import { createBreakpoints } from '@chakra-ui/theme-tools';

const devices = {
  phablet: '576px',
  tablet: '768px',
  desktop: '1200px',
  largeDesktop: '1200px',
  xlDesktop: '1300px',
};
const breakpoints = createBreakpoints({
  sm: devices.phablet,
  md: devices.tablet,
  lg: devices.desktop,
  xl: devices.largeDesktop,
  xxl: devices.xlDesktop,
  ...devices,
});

export default breakpoints;
