import { mode } from '@chakra-ui/theme-tools';
import { StyleFn } from '@emotion/react';

const light: StyleFn = ({ theme }) => ({
  background: theme.colors.brand.lightGrey,
  color: theme.colors.brand.primaryLight,
  fontWeight: 600,
  ':hover': {
    background: theme.colors.brand.primaryLight,
    color: theme.colors.brand.white,
  },
  ':active': {
    background: theme.colors.brand.primaryLight,
    color: theme.colors.brand.white,
  },
});

const dark: StyleFn = ({ theme }) => ({
  background: theme.colors.brand.primaryLight,
  color: theme.colors.brand.lightGrey,
  fontWeight: 600,
  ':hover': {
    background: theme.colors.brand.primary,
    color: theme.colors.brand.white,
  },
  ':active': {
    background: theme.colors.brand.primary,
    color: theme.colors.brand.white,
  },
});

const plain: StyleFn = props => ({
  background: 'transparent',
  color: mode(props.theme.colors.darkText, props.theme.colors.lightText)(props),
  boxShadow: 'none',
  ':hover': {
    background: 'transparent',
    color: mode(
      props.theme.colors.brand.darkGrey,
      props.theme.colors.brand.lightGrey
    )(props),
  },
  ':active': {
    background: 'transparent',
    color: mode(
      props.theme.colors.brand.darkGrey,
      props.theme.colors.brand.lightGrey
    )(props),
  },
});

const baseSize = {
  height: '40px',
  lineHeight: 1,
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '6px',
  paddingBottom: '6px',
  minHeight: '2.5rem',
  boxShadow: 'base',
};

export default {
  baseStyle: props => ({
    borderRadius: 10,
    ...mode(dark(props), light(props))(props),
  }),
  sizes: {
    sm: {
      ...baseSize,
      minHeight: '2.5rem',
      fontSize: 'sm',
    },
    lg: {
      ...baseSize,
      minHeight: '3.5rem',
      fontSize: 'md',
      height: '53px',
    },
  },
  variants: {
    base: {},
    light,
    dark,
    plain,
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
