export default {
  variants: {
    base: ({ theme }) => ({
      dialog: {
        background: theme.colors.brand.lightGrey,
      },
      body: {
        paddingTop: '3rem',
      },
    }),
  },
  defaultProps: {
    variant: 'base',
  },
};
