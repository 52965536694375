import { Styles } from '@chakra-ui/theme-tools';
import { Global, css } from '@emotion/react';

const GlobalStyleSheet = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;
export const GlobalStyles = () => <Global styles={GlobalStyleSheet} />;

const styles: Styles = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  global: props => ({
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      fontFamily: 'body',
      fontSize: 'base',
      backgroundColor: 'bg',
      color: 'text',
    },

    body: {
      width: '100%',
      position: 'absolute',
      overflowX: 'hidden',
      margin: '0px',
    },

    ul: {
      color: 'white',
    },

    // Utils
    '.disable-scroll': {
      overflowY: 'hidden',
    },
  }),
};

export default styles;
