import breakpoints from './breakpoints';
import colors from './colors';
import sizes from './sizes';
import space from './space';
import transitions from './transitions';
import typography from './typography';

const theme = {
  breakpoints,
  boxShadow: {
    dark: '0px 4px 41px rgba(159, 178, 200, 0.4)',
    light: '0px 4px 41px rgba(219, 225, 232, 0.395296)',
    input: 'rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px',
  },
  colors,
  space,
  ...typography,
  transitions,
  header: {
    height: 64,
  },
  sizes,
};

export type Theme = typeof theme;

export default theme;
