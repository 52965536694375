import { mode } from '@chakra-ui/theme-tools';
import { StyleFn } from '@emotion/react';

const simple: StyleFn = ({ theme }) => ({
  color: theme.colors.brand.blue,
  fontWeight: 600,
  ':visited': {
    color: theme.colors.brand.blue,
  },
  ':hover': {
    textDecoration: 'underline',
    borderBottomColor: 'transparent',
  },
});

const mutedSimple: StyleFn = () => ({
  color: 'brand.darkGrey',
  fontWeight: 600,
  ':visited': {
    color: 'brand.darkGrey',
  },
  ':hover': {
    textDecoration: 'underline',
    borderBottomColor: 'transparent',
  },
});

const blank: StyleFn = () => ({
  color: 'currentColor',
  ':visited': {
    color: 'currentColor',
  },
  ':hover': {
    textDecoration: 'none',
    borderBottomColor: 'transparent',
  },
});

const light: StyleFn = ({ theme }) => ({
  color: theme.colors.brand.white,
  fontWeight: 600,
  ':visited': {
    color: theme.colors.brand.lightPurple,
  },
  ':hover': {
    borderBottomColor: theme.colors.brand.white,
  },
});

const muted: StyleFn = () => ({
  color: 'brand.darkGrey',
  fontWeight: 600,
  ':visited': {
    color: 'brand.darkGrey',
  },
  ':hover': {
    borderBottomColor: 'brand.darkGrey',
  },
});

const dark: StyleFn = ({ theme }) => ({
  color: theme.colors.brand.blue,
  fontWeight: 600,
  ':visited': {
    color: theme.colors.brand.lightPurple,
  },
  ':hover': {
    borderBottomColor: theme.colors.brand.blue,
  },
});

export default {
  baseStyle: props => ({
    cursor: 'pointer',
    paddingBottom: '4px',
    marginBottom: '-6px',
    borderBottom: '2px solid transparent',
    ...mode(dark(props), light(props))(props),
  }),
  variants: {
    light,
    dark,
    muted,
    mutedSimple,
    simple,
    blank,
    base: {},
  },
  defaultProps: {
    variant: 'base',
  },
};
