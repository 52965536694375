import { extendTheme, Theme as ChakraTheme } from '@chakra-ui/react';
import { ColorModeOptions } from '@chakra-ui/system';

import components from './components';
import foundations from './foundations';
import styles from './styles';

/**
 * Color mode config
 */
const config: ColorModeOptions = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const theme = {
  ...foundations,
  components,
  styles,
  config,
};

const extendedTheme = extendTheme(theme);

export type Theme = typeof theme & ChakraTheme;

export default extendedTheme;
