const brand = {
  primary: '#f32121',
  primaryLight: '#FF9750',
  secondary: '#121268',
  secondaryLight: '#1328BB',
  darkText: '#000000',
  greyText: '#E4E4E4',
  lightText: '#ffffff',
  blue: '#1D1E9D',
  darkIndigo: '#121368',
  lightPurple: '#2428BB',
  purple: '#978CF6',
  lightGrey: '#FFFFFF',
  mediumGrey: '#E4E4E4',
  darkGrey: '#000000',
  lightMedium: '#E1E1E1',
  white: '#fff',
  coral: '#FA642F',
};
export default {
  primary: brand.blue,
  border: brand.lightGrey,
  bg: 'white',
  darkText: brand.darkGrey,
  lightText: brand.lightGrey,
  brand,
};
