const typography = {
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 700,
  },

  fonts: {
    body: "'Open Sans', system-ui, sans-serif",
    heading: "'Bitter', serif",
  },

  fontSizes: {
    sm: '0.8em',
    base: '1em',
    md: '1.3em',
    lg: '1.5em',
    xl: '2.5em',
    xxl: '3em',
  },

  lineHeights: {
    sm: '1.5em',
    base: '1.5em',
    md: '1.25em',
    lg: '1.25em',
    xl: '1.15em',
    xxl: '1.15em',
  },
};

export type Typography = typeof typography;

export default typography;
