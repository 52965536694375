import { mode } from '@chakra-ui/theme-tools';

const light = ({ theme }) => ({
  color: theme.colors.lightText,
});

const dark = ({ theme }) => ({
  color: theme.colors.darkText,
});

const fancy = () => ({
  fontFamily: 'heading',
});

export const Heading = {
  baseStyle: props => ({
    fontFamily: 'heading',
    fontWeight: 'normal',
    ...mode(dark(props), light(props))(props),
  }),
  variants: {
    light,
    dark,
  },
};

export const Text = {
  baseStyle: props => mode(dark(props), light(props))(props),
  variants: {
    light,
    dark,
    fancy,
  },
};
