import Button from './button';
import Drawer from './drawer';
import Link from './link';
import List from './list';
import Tabs from './tabs';
import { Heading, Text } from './typography';

export default {
  Heading,
  Text,
  Link,
  Tabs,
  Button,
  Drawer,
  List,
};
