const size = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1440,
  mobileContentWidth: '560px',
};
export default {
  ...size,
};
