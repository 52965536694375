import { mode } from '@chakra-ui/theme-tools';

const light = ({ theme }) => ({
  tablist: {
    borderBottom: 'none',
    marginBottom: 5,
  },
  tab: {
    color: theme.colors.brand.greyText,
    paddingBottom: 2,
    marginBottom: -4,
    borderBottom: '2px solid transparent',
    transition: 'all 0.4s ease-out',

    _hover: {
      borderBottomColor: 'rgba(255,255,255,0.3)',
    },
    _selected: {
      color: theme.colors.lightText,
      borderBottomColor: theme.colors.lightText,
    },
  },
});

const dark = ({ theme }) => ({
  tablist: {
    borderBottom: 'none',
    marginBottom: 5,
  },
  tab: {
    color: theme.colors.darkText,
    paddingBottom: 2,
    marginBottom: -4,
    borderBottom: '2px solid transparent',

    _selected: {
      borderBottomColor: theme.colors.darkText,
    },
  },
});

export default {
  variants: {
    light,
    dark,
    base: props => mode(dark(props), light(props))(props),
  },
  defaultProps: {
    variant: 'base',
  },
};
