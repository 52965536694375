import 'focus-visible/dist/focus-visible';

import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';
import { ParallaxProvider } from 'react-scroll-parallax';

import { SignUpModal } from '@/components/storyblok/components';
import config from '@/config';
import { Provider as ModalProvider } from '@/lib/providers/modalProvider';
import FullStory from '@/services/fullstory';
import theme from '@/theme';
import { GlobalStyles } from '@/theme/styles';

export default function App({ Component, pageProps }): JSX.Element {
  const modalHandler = useDisclosure();

  return (
    <Sentry.ErrorBoundary>
      <GlobalStyles />
      <FullStory />
      <ChakraProvider resetCSS theme={theme}>
        <ModalProvider modalHandler={modalHandler}>
          <>
            <Head>
              <title>{config.title}</title>
              <meta
                content='width=device-width, initial-scale=1'
                name='viewport'
              />
              <meta content='ie=edge' httpEquiv='x-ua-compatible' />
            </Head>
            <ParallaxProvider>
              <AnimatePresence exitBeforeEnter>
                <Component {...pageProps} />
              </AnimatePresence>
            </ParallaxProvider>
          </>
          <SignUpModal handler={modalHandler} />
        </ModalProvider>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
}
