const scale = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 32,
  xl: 64,
};
export default {
  ...scale,
};
